import { formatAmount } from '@lib/utils/formatUtils'
import { useAppStore } from '@stores/app.store'

export const useFormatAmount = (amount: number) => {
  const store = useAppStore()
  if (!store.config) return ''

  const { currencyAcronym, currencySymbol, decimalsQty, decimalBehaviour } = store.config

  // In a future take care for example with another currencyAcronym for not compatible locale
  // CRC with 'es-CR' => ₡100,00
  // USD with 'es-CR' => USD 100,00
  return formatAmount(amount, {
    locale: store.locale,
    currencyAcronym,
    currencySymbol,
    decimalsQty,
    decimalBehaviour
  })
}
