/* eslint-disable camelcase */
import { createI18n } from 'vue-i18n'
import { type DeepPartial } from '../types/typesUtils'
import es from './locales/es.json'
import pt from './locales/pt.json'
import en from './locales/en.json'
import fr from './locales/fr.json'
import es_cr from './locales/variants/es_cr.json'

export type Locales = 'es' | 'pt' | 'en' | 'fr' | 'es-CR' // add more as needed 'es-AR', ...

/**
 * The `es` language needs to have all the available keys,
 * the rest can be partial
 *
 * To add a new locale:
 *  - Create the locales/{lang}.ts or /locales/variants/{lang}.ts
 *  - Declare the {lang} in `type Locales`
 *  - Import the file and add to `messages` object
 */

export type Messages = typeof es

export const i18nInstance = createI18n<{ message: DeepPartial<Messages> }, string>({
  legacy: false,
  locale: 'es',
  fallbackLocale: 'es',
  globalInjection: true,
  messages: {
    es,
    'es-CR': es_cr,
    pt,
    en,
    fr
  }
})
