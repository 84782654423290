<script lang="ts" setup>
import ConfirmDialog from '@components/ui/modal/ConfirmDialog.vue'
import AdkButton from '@components/ui/AdkButton.vue'
import { useCartActions } from '@composables/useCartActions'
import { useLocale } from '@composables/useLocale'
import { ConfirmDialogProps } from '@lib/models/modals'
import { createConfirmDialog } from 'vuejs-confirm-dialog'

const { t } = useLocale()
const { sendReset } = useCartActions()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dialog = createConfirmDialog(ConfirmDialog as any)

const showCancelModal = async () => {
  const dialogProps: ConfirmDialogProps = {
    title: t('modals.confirmCancelOrder.title'),
    description: t('modals.confirmCancelOrder.description'),
    primaryButton: t('actions.cancelOrder'),
    secondaryButton: t('actions.back')
  }
  const { isCanceled } = await dialog.reveal(dialogProps)

  if (isCanceled) return

  await sendReset()
}
</script>

<template>
  <AdkButton class="w-1/2" size="small" @press="showCancelModal">{{
    t('actions.cancelOrder')
  }}</AdkButton>
</template>
