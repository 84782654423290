import { isPlaywright } from './testUtils'

export const getRouteParams = (
  routeParams: { [key: string]: string | string[] },
  params: string[]
) => {
  const extracted: { [key: string]: string } = {}
  params.forEach((param) => {
    const routeParam = routeParams[param] || ''
    if (routeParam) {
      const value = Array.isArray(routeParam) ? routeParam[0] : routeParam
      extracted[param] = value
    }
  })
  return extracted
}

export const resetLinksPreload = () => {
  const linksPreload = document.querySelectorAll('[data-preload="1"]')
  for (const link of linksPreload) {
    document.head.removeChild(link)
  }
}

export const getLinkPreload = (imageUrl: string, fetchPriority?: boolean) => {
  const linkPreload = document.createElement('link')
  linkPreload.rel = 'preload'
  linkPreload.href = imageUrl
  linkPreload.as = 'image'
  linkPreload.dataset.preload = '1'
  if (fetchPriority) linkPreload.setAttribute('fetchpriority', 'high')
  return linkPreload
}

export const intervalFactor = () => {
  const factor = isPlaywright() ? 100 : 1000
  return factor
}

export const waitXSeconds = async (seconds: number) => {
  const factor = intervalFactor()
  const millis = seconds * factor
  await new Promise((resolve) => setTimeout(resolve, millis))
}

/* Helpers for disable click interaction on html */
export const disableGlobalUserInteraction = () => {
  const html = document.querySelector('html')
  if (!html) return
  html.classList.add('disable-interaction')
}

export const enableGlobalUserInteraction = () => {
  const html = document.querySelector('html')
  if (!html) return
  html.classList.remove('disable-interaction')
}
