import { disableGlobalUserInteraction, enableGlobalUserInteraction } from '@lib/utils/utils'

const handleRipple = (element: HTMLElement, binding, ev) => {
  disableGlobalUserInteraction()
  const dataRippleEl = element.dataset.ripple
  if (dataRippleEl !== 'overflow-visible') {
    element.style.overflow = 'hidden'
  }

  const rippleElement = document.createElement('span')
  let currentDiameter = 1
  let currentOpacity = 0.65
  const animationHandler = setInterval(animateRippleSpread, 5)
  applyRippleStyle()

  function applyRippleStyle() {
    const elementCoordinates = element.getBoundingClientRect()
    const offsetY = ev.clientY - elementCoordinates.y
    const offsetX = ev.clientX - elementCoordinates.x

    rippleElement.style.position = 'absolute'
    rippleElement.style.height = '5px'
    rippleElement.style.width = '5px'
    rippleElement.style.borderRadius = '100%'
    rippleElement.style.backgroundColor = '#dcdcdc' // is like ivory at 0.65 opacity ('#f9f9f9')
    rippleElement.style.left = `${offsetX}px`
    rippleElement.style.top = `${offsetY}px`

    // Evade rippleElement attach inside img tag
    const evTarget = ev.target as HTMLElement
    if (evTarget.tagName === 'IMG') {
      evTarget.parentElement?.appendChild(rippleElement)
    } else evTarget.appendChild(rippleElement)
  }

  function animateRippleSpread() {
    const maximalDiameter = +binding.value || 50
    if (currentDiameter <= maximalDiameter) {
      currentDiameter++
      currentOpacity -= 0.65 / maximalDiameter
      rippleElement.style.transform = `scale(${currentDiameter})`
      rippleElement.style.opacity = `${currentOpacity}`
    } else {
      element.style.overflow = 'visible'
      rippleElement.remove()
      clearInterval(animationHandler)
      enableGlobalUserInteraction()
    }
  }
}

// Hook the directive to the DOM element
const vRipple = {
  mounted: (el, binding) => {
    el.style.position = 'relative'
    el.addEventListener('click', (ev) => handleRipple(el, binding, ev))
  }
}

export default vRipple
