export const LogOwnLevel = {
  DEBUG: 'debug',
  INFO: 'info',
  ERROR: 'error'
} as const

export const LogKind = {
  ERROR: 'error',
  AXIOS_ERROR: 'axiosError',
  ZOD_ERROR: 'zodError',
  REQUEST: 'request',
  RESPONSE: 'response',
  DEBUG: 'debug'
} as const

export interface RequestLogConfig {
  method: string
  url: string
  params?: unknown
}

/* 
    Help for parsing later .log files

   - If !kind => could be an error message or info message depending on type ('error' | 'info')
   # if type === 'error'
    - if kind === 'error', is a logic error in a request (not axios or zod), very rare
    - if kind === 'axiosError', is a axios error from request
    - if kind === 'zodError', is a zod error parsing response from request
   # if type === 'debug'
    - if kind === 'debug' data could be anything (not used right now)
    - if kind === 'request' is the intent of a request (nº, config, body)
    - if kind === 'response' is the response of request (nº, status, config, body)
*/

export type LogBody =
  | { type: 'info' | 'error'; kind: undefined; message: string } // logInfo, logError - simple messages .info, .error
  | { type: 'error'; kind: 'error'; data: { requestUrl: string; message: string } } // logErrorDebug logic error
  | { type: 'error'; kind: 'zodError'; data: { requestUrl: string; errors: unknown } } // logErrorDebug zodError
  | {
      type: 'error'
      kind: 'axiosError'
      data: { status: number; requestUrl: string; response: unknown }
    } // logErrorDebug axiosError
  | {
      type: 'debug'
      kind: 'request'
      data: { number: number; config: RequestLogConfig; body?: unknown }
    } // logInfoDebug for requests
  | {
      type: 'debug'
      kind: 'response'
      data: { number: number; status: number; config: RequestLogConfig; body?: unknown }
    } // logDebug for response
  | {
      type: 'debug'
      kind: 'debug'
      data: unknown
    } // not using right now, unknow data

/* Inside log files will be LogRecord(s) separated by \n */

/* interface LogRecord {
  date: string // iso UTC string format
  scope: string // to localize component/service throwing log
  level: string // electronLog LogLevel , by default on file we save from 'info' upwards ('info','warn','error' will be saved), response (as 'debug' logLevel) is not printed by default
  log: LogBody
} */
