import { BackendService } from '../backend.service'
import { scopedLogger } from '@lib/utils/logUtils'
import { LogFunctions } from 'electron-log'
import { userDto } from '@lib/models/identification'

class IdentificationService extends BackendService {
  protected logger: LogFunctions

  constructor() {
    super('identification')

    this.logger = scopedLogger('@services/backend/identification')
  }

  async logginUser(input: { document?: string; mcId?: string }) {
    return await this.request(
      {
        method: 'GET',
        url: '/customer',
        params: {
          ...input
        }
      },
      userDto,
      this.logger
    )
  }
}

export const identificationService = new IdentificationService()
