<script lang="ts" setup>
import type DotLottiePlayer from '@aarsteinmedia/dotlottie-player-light'
import { ref } from 'vue'

/**
 * Usage
 * https://www.npmjs.com/package/@aarsteinmedia/dotlottie-player-light-light
 * 
 * By default animation is loaded and autoplay is setted by true (if not overrided on parent), and loop to false (if not overrided)
 * 
 * Lottie.vue exposes controller so on parent when can access all methods of DotLottiePlayer (play, pause, stop, setDirection ...),
 * when is needed control to animation play state (playing programatically ... etc)
 * 
 * # For example, on parent component #
 * const lottie = ref<InstanceType<typeof Lottie> | null>(null)
 * 
 * <Lottie ref="lottie" :lottie-key="lottieKey" @ready="onReady" ... />
 * 
 * Handle event @ready from Lottie component when lottie is loaded and player ready
 * 
 * const onReady = async () => {
    if (!lottie.value?.controller) return
    // pause in 1s
    await new Promise((resolve) => setTimeout(resolve, 1000))
    lottie.value.controller.pause()
    console.log('player state', lottie.value.controller.playerState)
    // play on reverse in 1s
    await new Promise((resolve) => setTimeout(resolve, 1000))
    lottie.value.controller.setDirection(-1)
    lottie.value.controller.play()
  }
 *  */

/*  */
const emit = defineEmits(['ready', 'play', 'stop', 'complete'])
const props = defineProps<{
  lottieKey: string
}>()

const lottieSrc = `lottie/${props.lottieKey}.lottie`

const controller = ref<DotLottiePlayer>()

defineExpose({
  controller
})
</script>

<template>
  <dotlottie-player
    ref="controller"
    v-bind="$attrs"
    :src="lottieSrc"
    :autoplay="$attrs.autoplay ?? true"
    :loop="$attrs.loop ?? false"
    @ready="emit('ready')"
    @play="emit('play')"
    @stop="emit('stop')"
    @complete="emit('complete')"
  />
</template>
