import { z } from 'zod'

export const userDto = z.object({
  external_id: z.string(),
  first_name: z.string(),
  available_points: z.number()
})

const customUserDto = z.object({
  mcId: z.string(),
  name: z.string(),
  availablePoints: z.number()
})

export type User = z.infer<typeof customUserDto>
