<script lang="ts" setup>
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    isPrimary?: boolean
    size?: 'extralarge' | 'large' | 'medium' | 'small'
    isSelected?: boolean
    disabled?: boolean
  }>(),
  {
    isPrimary: false,
    size: 'medium',
    isSelected: false,
    disabled: false
  }
)
const emit = defineEmits(['press'])

const ADK_BUTTON_CLASS = {
  PRIMARY: 'btn-primary',
  SECONDARY: 'btn-secondary',
  // sizes
  EXTRALARGE: 'btn-extralarge',
  LARGE: 'btn-large',
  MEDIUM: 'btn-medium',
  SMALL: 'btn-small'
} as const

const computedClass = computed(() => {
  let strClass = ''
  strClass += props.isPrimary ? ADK_BUTTON_CLASS.PRIMARY : ADK_BUTTON_CLASS.SECONDARY
  strClass += ' '
  if (props.size === 'extralarge') strClass += ADK_BUTTON_CLASS.EXTRALARGE
  else if (props.size === 'large') strClass += ADK_BUTTON_CLASS.LARGE
  else if (props.size === 'small') strClass += ADK_BUTTON_CLASS.SMALL
  else strClass += ADK_BUTTON_CLASS.MEDIUM

  if (props.isSelected && props.isPrimary) strClass += ' border-adk-accent border-[0.28vw]'
  else if (props.isSelected) strClass += ' border-adk-main border-[0.28vw]'

  return strClass
})
</script>

<template>
  <button
    v-ripple
    :disabled="disabled"
    v-bind="$attrs"
    class="flex flex-row gap-[1.48vw] justify-center items-center"
    :class="computedClass"
    @click="emit('press', $event)"
    @keydown.prevent.enter="emit('press', $event)"
  >
    <slot></slot>
  </button>
</template>
