import { z } from 'zod'
import { stringDefaultNullable, localImageSchema } from './common'

export const DecimalBehaviour = {
  ROUND_UP: 'roundUp',
  ROUND_GENERIC: 'roundGeneric',
  TRUNCATE: 'trunc'
} as const

const decimalBehaviourDto = z.union([
  z.literal(DecimalBehaviour.TRUNCATE),
  z.literal(DecimalBehaviour.ROUND_GENERIC),
  z.literal(DecimalBehaviour.ROUND_UP)
])

const attractImageDto = z.object({
  urlTotem: localImageSchema
})

export const attractImagesDto = z.array(attractImageDto)

export type AttractImages = z.infer<typeof attractImagesDto>

const attractScreenDto = z.object({
  images: attractImagesDto
})

export const FiscalFieldsName = {
  DOCUMENT: 'document',
  DOCUMENT_TYPE: 'type',
  NAME: 'name',
  EMAIL: 'email'
} as const

const fiscalFieldOptionsDto = z.object({
  name: z.string(),
  integrationFlexId: z.string(),
  integrationImId: z.string(),
  defaultLoyaltyDocument: z.boolean().optional(),
  loyaltyDocument: z.boolean().optional(),
  mask: stringDefaultNullable,
  regex: stringDefaultNullable
})

export type FiscalFieldOption = z.infer<typeof fiscalFieldOptionsDto>

const fiscalFieldsDto = z.object({
  code: z.string(),
  name: z.string(),
  mask: stringDefaultNullable,
  regex: stringDefaultNullable,
  options: z.array(fiscalFieldOptionsDto).optional(),
  required: z.boolean()
})

export type FiscalField = z.infer<typeof fiscalFieldsDto>

const dynamicFiscalFieldsDto = z.array(fiscalFieldsDto)

const deliveryMethodConfiguration = z.object({
  hasOwnSeating: z.boolean(),
  eatHere: z.object({
    available: z.boolean()
  }),
  takeAway: z.object({
    available: z.boolean()
  })
})

export const configurationDto = z.object({
  // general
  country: z.string(),
  digitsOrder: z.number().optional().default(3),
  maxCardPaymentAmount: z.number().nullable(),
  // locale
  defaultLanguage: z.string(),
  // currency
  currencyAcronym: z.string(),
  currencySymbol: z.string(),
  decimalsQty: z.number().optional().default(2),
  decimalBehaviour: decimalBehaviourDto.optional().default(DecimalBehaviour.TRUNCATE),
  // attract screen
  attractScreen: attractScreenDto,
  // identification
  dynamicFiscalFields: dynamicFiscalFieldsDto,
  // options
  deliveryOptions: deliveryMethodConfiguration.optional().default({
    hasOwnSeating: false,
    eatHere: {
      available: true
    },
    takeAway: {
      available: false
    }
  })
})

export type Configuration = z.infer<typeof configurationDto>
