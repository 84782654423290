import { DecimalBehaviour } from '@lib/models/config'
import { CurrencyFormatOverride, CurrencyLocaleOverride } from '@lib/models/internal/currency'
import { MaskTokens } from 'maska'

/* Locale/Translations */
// TO DO - need to return of Type Locales to enable typing at useLocale and i18n
export const extractLocale = (defaultLocale: string) => {
  const [fallbackLocaleVal, region] = defaultLocale.split(/[_-]/)
  if (!region)
    return {
      fallbackLocaleVal,
      localeVal: fallbackLocaleVal
    }

  return {
    fallbackLocaleVal,
    localeVal: `${fallbackLocaleVal}-${region}`
  }
}

/* CurrencyFormatter */

export const setDecimals = (
  amount: number,
  { decimalsQty, decimalBehaviour }: { decimalsQty: number; decimalBehaviour: string }
): number => {
  const allowDecimals = !!decimalsQty
  const maxDecimals = !allowDecimals ? 0 : decimalsQty

  const base = 10
  const fractionedAmount = amount / base
  const operator = Math.pow(base, maxDecimals - 1)
  const actions: { [key: string]: () => number } = {
    [DecimalBehaviour.TRUNCATE]: () => Math.trunc(fractionedAmount * operator) / operator,
    [DecimalBehaviour.ROUND_UP]: () => Math.ceil(fractionedAmount * operator) / operator,
    [DecimalBehaviour.ROUND_GENERIC]: () => Math.round(fractionedAmount * operator) / operator
  }

  return actions[decimalBehaviour]() * base
}

export const formatAmount = (
  amount: number,
  {
    locale,
    currencyAcronym,
    currencySymbol,
    decimalsQty,
    decimalBehaviour
  }: {
    locale: string
    currencyAcronym: string
    currencySymbol: string
    decimalsQty: number
    decimalBehaviour: string
  }
) => {
  const style = 'currency'
  const amountRounded = setDecimals(amount, { decimalsQty, decimalBehaviour })
  const total = amountRounded / 100

  // check count of integer chars in total
  const isCompactNotation = parseInt(total.toString()).toString().length > 5

  const localeOverride: string = CurrencyLocaleOverride[currencyAcronym] || locale

  const formatter = new Intl.NumberFormat(localeOverride, {
    style,
    currency: currencyAcronym,
    currencyDisplay: 'code',
    useGrouping: 'always',
    minimumFractionDigits: decimalsQty,
    maximumFractionDigits: decimalsQty,
    // more than 5 digits, show compact formatting (125,50 mil ₡, 1,25 M₡ ... ) by locale
    ...(isCompactNotation
      ? {
          notation: 'compact',
          compactDisplay: 'short',
          roundingMode: 'trunc'
        }
      : {})
  })

  // Custom currency formatting
  if (CurrencyFormatOverride[currencyAcronym] && !isCompactNotation) {
    const customFormat = CurrencyFormatOverride[currencyAcronym]
    const formatParts = formatter.formatToParts(total)
    const formatStr = formatParts.reduce((acc, curr) => {
      if (curr.type === 'group') acc += customFormat.group || curr.value
      else if (curr.type === 'decimal') acc += customFormat.decimal || curr.value
      else if (curr.type === 'currency') {
        acc += currencySymbol
        return acc
      } else acc += curr.value
      return acc
    }, '')
    return formatStr
  }

  return formatter.format(total).replace(currencyAcronym, currencySymbol)
}

const vMaskaTokensKeys = {
  NUMERIC: '#',
  ALPHANUMERIC: 'N',
  LETTERS: 'A'
} as const

const vMaskaTokens = {
  [vMaskaTokensKeys.NUMERIC]: '[0-9]',
  [vMaskaTokensKeys.ALPHANUMERIC]: '[0-9a-zA-Z]',
  [vMaskaTokensKeys.LETTERS]: '[a-zA-Z]'
} as const

export const dataMaskaTokensStr = JSON.stringify({
  [vMaskaTokensKeys.NUMERIC]: { pattern: vMaskaTokens[vMaskaTokensKeys.NUMERIC] },
  [vMaskaTokensKeys.ALPHANUMERIC]: { pattern: vMaskaTokens[vMaskaTokensKeys.ALPHANUMERIC] },
  [vMaskaTokensKeys.LETTERS]: { pattern: vMaskaTokens[vMaskaTokensKeys.LETTERS] }
})

export const vMaskaMaskTokens: MaskTokens = {
  [vMaskaTokensKeys.NUMERIC]: { pattern: new RegExp(vMaskaTokens[vMaskaTokensKeys.NUMERIC]) },
  [vMaskaTokensKeys.ALPHANUMERIC]: {
    pattern: new RegExp(vMaskaTokens[vMaskaTokensKeys.ALPHANUMERIC])
  },
  [vMaskaTokensKeys.LETTERS]: { pattern: new RegExp(vMaskaTokens[vMaskaTokensKeys.LETTERS]) }
}

export const formatNumeric = (amount: number, locale: string, decimalsQty: number) => {
  const amountIntegerCharLength = parseInt(amount.toString()).toString().length

  const formatter = new Intl.NumberFormat(locale, {
    maximumFractionDigits: decimalsQty,
    // more than 5 digits, show compact formatting (125,50 mil ₡, 1,25 M₡ ... )
    ...(amountIntegerCharLength > 5
      ? {
          notation: 'compact',
          compactDisplay: 'short',
          roundingMode: 'trunc'
        }
      : {})
  })

  return formatter.format(amount)
}

export const formatOrderNumber = (orderNumber: number, digitsOrder: number) =>
  orderNumber.toString().padStart(digitsOrder, '0')

// Capitalize
export const capitalizeName = (str: string) => {
  const words = str.split(' ')
  let capitalizedName = ''
  words.forEach((word) => {
    capitalizedName += `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()} `
  })

  return capitalizedName.trimEnd()
}
