<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    url?: string
    alt: string
    loading?: 'lazy' | 'eager'
    fallbackImage?: string
  }>(),
  {
    url: 'images/placeholder.svg',
    loading: undefined,
    fallbackImage: 'images/placeholder.svg'
  }
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onLoadError = (ev: any) => {
  if (ev.target?.src && !ev.target.src.includes('placeholder')) ev.target.src = props.fallbackImage
}
</script>

<template>
  <img :src="url" :alt="alt" :loading="loading" @error="onLoadError" />
</template>
