<script lang="ts" setup>
import AdkButton from '@components/ui/AdkButton.vue'
import { useLocale } from '@composables/useLocale'

const { t } = useLocale()
</script>

<template>
  <AdkButton class="w-1/2" size="large">
    <img src="/images/icons/language.svg" alt="accessibility" class="w-[4.44vw] h-auto" />
    <p class="text-start">{{ t('actions.changeLanguage') }}</p>
  </AdkButton>
</template>
