import { scopedLogger, logInfo } from '@lib/utils/logUtils'
import { useAppStore } from '@stores/app.store'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const log = scopedLogger('@composables/useCartActions.vue')

export const useCartActions = () => {
  const store = useAppStore()
  const router = useRouter()

  const cartUnits = computed(() => store.cartUnits)
  const accessibleMode = computed(() => store.accessibility)

  const viewCrossSelling = async () => {
    await router.push({ name: 'crossSelling' })
  }

  const viewCart = async () => {
    await router.push({ name: 'cart' })
  }

  const navigateSeeOrder = async () => {
    if (!store.crossSellingIsViewed) await viewCrossSelling()
    else await viewCart()
  }

  const sendReset = async () => {
    store.resetADK = true
    logInfo(log, 'Send RESET ADK STATE, going to splash')
    // go to splash
    await router.push({ name: 'splash' })
  }

  const changeAccesibleMode = () => {
    store.setAccessibility(!store.accessibility)
  }

  return { cartUnits, accessibleMode, navigateSeeOrder, sendReset, changeAccesibleMode }
}
