import { ref } from 'vue'

const CLICKS_TO_SHUT_DOWN = 10

export const useShutdownApp = () => {
  const totalClicks = ref(0)

  const handleHeaderClick = () => {
    totalClicks.value++
    if (totalClicks.value === CLICKS_TO_SHUT_DOWN) window.electron.ipcRenderer.send('shutdown-app')
  }

  return { handleHeaderClick }
}
