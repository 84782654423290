<script lang="ts" setup>
import AdkButton from '@components/ui/AdkButton.vue'
import { useCartActions } from '@composables/useCartActions'
import { useLocale } from '@composables/useLocale'

const props = withDefaults(
  defineProps<{
    isAttract?: boolean
  }>(),
  {
    isAttract: false
  }
)
const size = () => (props.isAttract ? 'large' : 'small')

const { t } = useLocale()
const { accessibleMode, changeAccesibleMode } = useCartActions()
</script>

<template>
  <AdkButton class="w-1/2 px-[1.48vw]" :size="size()" @press="changeAccesibleMode">
    <img
      src="/images/icons/accessibility.svg"
      alt="accessibility"
      class="w-[2.22vw] h-auto"
      :class="{ 'opacity-50': accessibleMode, 'w-[4.44vw]': isAttract }"
    />
    <p>{{ t('actions.accessibility') }}</p></AdkButton
  >
</template>
