<script lang="ts" setup>
import { useFormatAmount } from '@composables/useFormatAmount'
import { useAppStore } from '@stores/app.store'
import CartBag from '../cart/CartBag.vue'

const store = useAppStore()

const format = (amount: number) => useFormatAmount(amount)
</script>

<template>
  <div class="self-end flex flex-row gap-[0.74vw] overflow-hidden">
    <CartBag :units="store.cartUnits" />
    <p
      data-test="footer-cart__price"
      class="self-end text-h3 leading-[2.08vh] font-bold overflow-hidden text-ellipsis"
    >
      {{ format(store.cartTotal) }}
    </p>
  </div>
</template>
