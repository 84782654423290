import z from 'zod'
import { localImageSchema, optionsGroupsTypeSchema, priceSchema } from './common'

// Second level options => optionGroups.[0].options.[0].optionGroups.[0].options
const optionBaseSchema = z
  .object({
    identifier: z.string(), // is "identifier"
    name: z.string(),
    min: z.number(),
    max: z.number(),
    // inmutable qty
    qty: z.number(),
    available: z.boolean(),
    price: priceSchema,
    imageUrl: localImageSchema,
    hide: z.boolean().optional().default(false),
    // to modify units
    units: z.number().optional()
  })
  .transform((optionChild) => {
    // set units on parse response
    return { ...optionChild, units: optionChild.units ?? optionChild.qty }
  })

export type OptionChild = z.infer<typeof optionBaseSchema>

const optGroupBase = z.object({
  identifier: z.string(), // is "identifier"
  title: z.string(),
  type: optionsGroupsTypeSchema,
  hide: z.boolean().optional().default(false),
  min: z.number(),
  max: z.number()
})

// Second level optionGroups => optionGroups.[0].options.[0].optionGroups.[0].options
const optionsGroupsChildSchema = optGroupBase.extend({
  options: z.array(optionBaseSchema)
})

export type OptionsGroupsChild = z.infer<typeof optionsGroupsChildSchema>

// First level options => optionGroups.[0].options
const optionSchema = z
  .intersection(
    optionBaseSchema,
    z.object({
      optionsGroups: z.array(optionsGroupsChildSchema),
      // to modify units
      units: z.number().optional()
    })
  )
  .transform((option) => {
    // set units on parse response
    return { ...option, units: option.units ?? option.qty }
  })

export type OptionMain = z.infer<typeof optionSchema>

// First level optionGroups => optionGroups.[0]
export const optionsGroupsMainSchema = optGroupBase.extend({
  choiceGroup: z.string().optional(),
  options: z.array(optionSchema)
})

export type OptionsGroupsMain = z.infer<typeof optionsGroupsMainSchema>
