import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'
import DotLottiePlayer from '@aarsteinmedia/dotlottie-player-light'
import 'vue3-toastify/dist/index.css'

import App from './App.vue'
import './assets/main.css'
import { i18nInstance } from './translations/i18n'
import router from './routes/router'
import vRipple from './directives/vRipple'
import vFitTextWidth from './directives/vFitTextWidth'
import { vMaska } from 'maska/vue'
import * as ConfirmDialog from 'vuejs-confirm-dialog'
import { dragscroll } from 'vue-dragscroll'

const pinia = createPinia()
const app = createApp(App)

// directives
app.directive('ripple', vRipple)
app.directive('maska', vMaska)
app.directive('fitTextWidth', vFitTextWidth)
app.directive('dragscroll', dragscroll)

// plugins
app.use(Vue3Toastify, {
  // duration default
  autoClose: 5000,
  containerId: 'adk-toasts',
  position: 'bottom-center',
  // behaviour
  icon: false,
  hideProgressBar: true,
  closeButton: false,
  newestOnTop: true,
  // style classes
  containerClassName: 'adk-toast__container',
  toastClassName: 'adk-toast__toast',
  bodyClassName: 'adk-toast__body'
} as ToastContainerOptions)
app.use(i18nInstance, { router })
app.use(router)
app.use(VueDOMPurifyHTML)
app.use(ConfirmDialog)
app.use(pinia)

// components
app.component('DotLottiePlayer', DotLottiePlayer)

app.mount('#app')
