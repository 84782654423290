import { AxiosError, isAxiosError } from 'axios'
import { ZodError } from 'zod'

export type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]> } : T

export const isZodError = (err: unknown): err is ZodError => {
  return Boolean(err && (err instanceof ZodError || (err as ZodError).name === 'ZodError'))
}

export const isAxiosApiError = (err: unknown): err is AxiosError => {
  return isAxiosError(err)
}

export const getErrorType = (err: unknown) => {
  if (isZodError(err)) return 'ZOD'
  else if (isAxiosApiError(err)) return 'AXIOS'
  else return 'LOGIC'
}
