const isOverflowedEl = (el: HTMLElement): boolean => {
  const { scrollWidth, clientWidth } = el
  return clientWidth < scrollWidth
}

const handleFitText = (el: HTMLElement): void => {
  const { fontSize } = window.getComputedStyle(el)
  const px = parseFloat(fontSize.replace('px', ''))
  el.style.fontSize = `${px * 0.95}px`
}

// Hook the directive to the DOM element
const vFitTextWidth = {
  mounted: (el: HTMLElement) => {
    el.style.maxWidth = '100%'
    el.style.overflowX = 'auto'
    el.style.overflowY = 'clip'

    let isOverflowed = isOverflowedEl(el)

    while (isOverflowed) {
      handleFitText(el)
      isOverflowed = isOverflowedEl(el)
    }
  }
}

export default vFitTextWidth
