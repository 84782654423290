import BlankLayout from './BlankLayout.vue'
import HomeLayout from './HomeLayout.vue'
import AttractLayout from './AttractLayout.vue'
import PickUpSelectionLayout from './PickUpSelectionLayout.vue'

export default {
  BlankLayout,
  HomeLayout,
  AttractLayout,
  PickUpSelectionLayout
}
