import { AttractImages, Configuration, FiscalFieldsName } from '@lib/models/config'
import { Block } from '@lib/models/blocks'
import { Cart } from '@lib/models/purchase'
import { Category } from '@lib/models/category'
import { scopedLogger, logInfo } from '@lib/utils/logUtils'
import { defineStore } from 'pinia'
import { purchaseService } from '@lib/services/backend/purchase/purchase.service'
import { SaleType, PriceTotals } from '@lib/models/common'
import { ProductLiteCategorized } from '@lib/models/product'
import { FiscalFields } from '@lib/models/internal/checkout'
import { User } from '@lib/models/identification'
import { identificationService } from '@lib/services/backend/identification/identification.service'
import { capitalizeName } from '@lib/utils/formatUtils'

const log = scopedLogger('@stores/app.store.ts')

interface State {
  locale: string
  config?: Configuration
  categories: Array<Category>
  accessibility: boolean
  resetADK: boolean
  // view
  viewCategory?: Category
  // content
  homeBlocks?: Array<Block>
  // crossSelling
  crossSellingIsViewed: boolean
  crossSellingProducts?: Array<ProductLiteCategorized>
  // purchaseSession
  cart?: Cart
  fiscalFields?: FiscalFields
  cardMethodUnavailable: boolean
  // toggleLoginTutorial footer
  showLoginTutorial: boolean
  // user
  user?: User
  // aop coupons
  aopCouponProducts?: ProductLiteCategorized[]
}

export const useAppStore = defineStore('app', {
  state: (): State => ({
    locale: '',
    config: undefined,
    categories: [],
    accessibility: false,
    resetADK: false,
    viewCategory: undefined,
    homeBlocks: undefined,
    crossSellingIsViewed: false,
    crossSellingProducts: undefined,
    cart: undefined,
    fiscalFields: undefined,
    cardMethodUnavailable: false,
    showLoginTutorial: false
  }),
  getters: {
    cartTotal(): number {
      if (!this.cart) return 0
      return this.cart.totals.total
    },
    cartTotals(): PriceTotals {
      if (!this.cart)
        return {
          total: 0,
          subtotal: 0,
          tax: 0,
          discount: 0,
          points: 0
        }
      return this.cart.totals
    },
    cartUnits(): number {
      if (!this.cart) return 0
      return this.cart.productsTotals.reduce((acc, prodGroup) => {
        acc += prodGroup.units
        return acc
      }, 0)
    },
    attractImages(): AttractImages | undefined {
      if (!this.config) return undefined
      return this.config?.attractScreen.images
    },
    documentMask(): string {
      if (!this.config?.dynamicFiscalFields) return ''
      const typeFieldOptions = this.config.dynamicFiscalFields.find(
        (fiscalField) => fiscalField.code === FiscalFieldsName.DOCUMENT_TYPE
      )?.options

      const mask = typeFieldOptions?.find(
        (data) => data.defaultLoyaltyDocument && data.loyaltyDocument
      )?.mask

      return mask ?? ''
    }
  },
  actions: {
    async executeResetADK() {
      if (this.cart?.sessionId) {
        try {
          // end purchaseSession
          await this.endPurchaseSession(this.cart.sessionId)
        } catch (error) {
          this.setCart() // to undefined
        }
      }
      // reset store
      this.homeBlocks = undefined
      this.crossSellingProducts = undefined
      this.crossSellingIsViewed = false
      this.accessibility = false
      this.showLoginTutorial = false
      this.cardMethodUnavailable = false
      // user related
      await this.clearUserData({ signOut: false })
      logInfo(log, 'RESETTED ADK STATE succesfully')
      this.resetADK = false
    },
    setConfiguration(config?: Configuration) {
      this.config = config
      logInfo(log, 'Setted configuration')
    },
    setCategories(categories: Array<Category>) {
      this.categories = categories
      logInfo(log, 'Setted categories')
    },
    setAccessibility(val: boolean) {
      this.accessibility = val
    },
    // views
    setViewCategory(category?: Category) {
      this.viewCategory = category
    },
    // content
    setHomeBlocks(homeBlocks: Array<Block>) {
      this.homeBlocks = homeBlocks
    },
    // crossSelling
    setCrossSellingProducts(products: Array<ProductLiteCategorized>) {
      this.crossSellingProducts = products
      this.crossSellingIsViewed = true
    },
    // purchaseSession
    setCart(cart?: Cart) {
      this.cart = cart
      logInfo(log, 'Setted cart')
    },
    // user
    setUser(user?: User) {
      this.user = user
    },
    async initPurchaseSession(saleType: SaleType) {
      logInfo(log, 'fetch purchaseSession start')
      try {
        this.cart = await purchaseService.fetchStartPurchaseSession(saleType)
        logInfo(
          log,
          'purchaseSession started with success, sessionId = ' +
            this.cart.sessionId +
            ', saleType = ' +
            this.cart.saleType
        )
      } catch (error) {
        logInfo(log, 'Something was wrong on starting purchaseSession')
        throw error
      }
    },
    async endPurchaseSession(sessionId: string) {
      logInfo(log, 'fetch purchaseSession end')
      try {
        await purchaseService.fetchEndPurchaseSession(sessionId)
        this.setCart() // to undefined
        logInfo(log, 'purchaseSession ended with success')
      } catch (error) {
        throw new Error('purchaseSession could not be ended')
      }
    },
    setFiscalFields(fiscalFields?: FiscalFields) {
      this.fiscalFields = fiscalFields
      logInfo(log, 'Setted fiscal fields => ' + JSON.stringify(fiscalFields || {}))
    },
    toggleLoginTutorial() {
      this.showLoginTutorial = !this.showLoginTutorial
    },
    async logInUserAction(input: { document?: string; mcId?: string }) {
      try {
        const user = await identificationService.logginUser(input)
        // previously logged
        if (this.user) {
          await this.clearUserData({ signOut: false })
        }
        this.user = {
          mcId: user.external_id,
          availablePoints: user.available_points,
          name: capitalizeName(user.first_name)
        }
        return this.user
      } catch (error) {
        logInfo(log, 'User data could not be obtained')
        return undefined
      }
    },
    async clearUserData({ signOut = true }) {
      this.fiscalFields = undefined
      this.user = undefined
      if (signOut && this.cart?.coupons.length) {
        // clear coupons from purchaseSession
        try {
          logInfo(log, 'Clearing coupons or user related from purchase session')
          this.cart = await purchaseService.fetchCleanCouponsPurchaseSession(this.cart.sessionId)
        } catch (err) {
          logInfo(log, 'There was an error clearing purchaseSession coupons ')
        }
      }
      logInfo(log, 'Cleared user data')
    },
    setAopCouponProducts(products?: ProductLiteCategorized[]) {
      this.aopCouponProducts = products
    },
    setCardMethodUnavailable(val: boolean) {
      this.cardMethodUnavailable = val
    }
  }
})
