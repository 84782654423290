<script lang="ts" setup>
import { useShutdownApp } from '@composables/useShutDownApp'

const { handleHeaderClick } = useShutdownApp()
</script>

<template>
  <header data-test="header" class="h-[10vh] w-full flex justify-center">
    <div class="mb-[1.25vh] mt-auto gap-[8.89vw] items-center">
      <img
        src="/images/mcd.png"
        alt="mcdonalds"
        class="self-center h-[5.83vh] w-auto"
        @click="handleHeaderClick"
      />
    </div>
  </header>
</template>
