import { CartAddInput, CartModifyInput, cartDto, endSessionDto } from '@lib/models/purchase'
import { BackendService } from '../backend.service'
import { scopedLogger } from '@lib/utils/logUtils'
import { LogFunctions } from 'electron-log'
import { SaleType } from '@lib/models/common'

class PurchaseService extends BackendService {
  protected logger: LogFunctions

  constructor() {
    super('purchase')

    this.logger = scopedLogger('@services/backend/purchase')
  }

  async fetchStartPurchaseSession(saleType: SaleType) {
    return await this.request(
      {
        method: 'POST',
        url: '/purchase/start',
        data: {
          saleType
        }
      },
      cartDto,
      this.logger
    )
  }

  async fetchEndPurchaseSession(sessionId: string) {
    return await this.request(
      {
        method: 'POST',
        url: `/purchase/end/${sessionId}`
      },
      endSessionDto,
      this.logger
    )
  }

  async fetchAddProduct(input: CartAddInput) {
    return await this.request(
      {
        method: 'POST',
        data: input,
        url: '/purchase/add'
      },
      cartDto,
      this.logger
    )
  }

  async fetchModifyProduct(input: CartModifyInput) {
    return await this.request(
      {
        method: 'POST',
        data: input,
        url: '/purchase/modify'
      },
      cartDto,
      this.logger
    )
  }

  // fully empty cart
  async fetchCleanupPurchaseSession(sessionId: string) {
    return await this.request(
      {
        method: 'POST',
        url: `/purchase/cleanup/${sessionId}`
      },
      cartDto,
      this.logger
    )
  }

  // clear user related info of session, for example coupons
  async fetchCleanCouponsPurchaseSession(sessionId: string) {
    return await this.request(
      {
        method: 'POST',
        url: `/purchase/clean-coupons/${sessionId}`
      },
      cartDto,
      this.logger
    )
  }

  async fetchPurchaseSession(sessionId: string) {
    return await this.request(
      {
        method: 'GET',
        url: `/purchase/${sessionId}`
      },
      cartDto,
      this.logger
    )
  }
}

export const purchaseService = new PurchaseService()
