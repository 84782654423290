import { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { scopedLogger, logDebug, logInfoDebug } from './logUtils'
import { LogBody, LogOwnLevel, LogKind, RequestLogConfig } from '@lib/models/internal/log'

const reqId = new WeakMap<InternalAxiosRequestConfig, number>()
let reqIdCounter = 0

const buildRequestConfig = (
  config: AxiosRequestConfig | InternalAxiosRequestConfig
): RequestLogConfig => {
  const method = config.method?.toUpperCase() ?? 'UNKNOWN'
  const url = `${config.baseURL}${config.url}`

  return {
    method,
    url,
    params: config.params
  }
}

export default function logRequests(serviceSufix: string, client: AxiosInstance) {
  const log = scopedLogger(`@services/${serviceSufix}`)

  const logRequest = function logRequest(config: InternalAxiosRequestConfig) {
    reqId.set(config, reqIdCounter++)
    const logBodyRequest: LogBody = {
      type: LogOwnLevel.DEBUG,
      kind: LogKind.REQUEST,
      data: {
        number: reqId.get(config) ?? 0,
        config: buildRequestConfig(config),
        body: config.data
      }
    }
    logInfoDebug(log, logBodyRequest)

    return config
  }

  const logResponse = function logResponse(res: AxiosResponse): AxiosResponse {
    const reqConfig = buildRequestConfig(res.config)

    const logBodyResponse: LogBody = {
      type: LogOwnLevel.DEBUG,
      kind: LogKind.RESPONSE,
      data: {
        number: reqId.get(res.config) ?? 0,
        config: reqConfig,
        status: res.status || 500,
        body: res.data || undefined
      }
    }
    logDebug(log, logBodyResponse)

    return res
  }

  // Use interceptors
  client.interceptors.request.use(logRequest)
  client.interceptors.response.use(logResponse)
}
