<script lang="ts" setup>
import AdkButton from '@components/ui/AdkButton.vue'
import { ConfirmDialogProps } from '@lib/models/modals'
import { onMounted, ref } from 'vue'
import BaseDialog from './BaseDialog.vue'

defineProps<ConfirmDialogProps>()

const emit = defineEmits(['confirm', 'cancel'])

const shown = ref(false)

const waitXMillis = async (millis: number) =>
  await new Promise((resolve) => setTimeout(resolve, millis))

const doAction = async (action: 'confirm' | 'cancel') => {
  shown.value = false
  await waitXMillis(50)
  emit(action)
}

onMounted(async () => {
  await waitXMillis(50)
  shown.value = true
})
</script>

<template>
  <BaseDialog>
    <div
      v-if="shown"
      data-test="confirm-dialog"
      class="w-full h-full fixed top-0 left-0 z-10 bg-white flex flex-col justify-center"
    >
      <section class="flex flex-col items-center gap-[4.58vh] pb-32">
        <div class="flex flex-col w-[78.98vw] gap-[2.92vh] items-center">
          <img
            class="h-[7.50vh] w-[13.33vw]"
            :src="imageUrl ?? `images/navigation/warning.svg`"
            :alt="title"
          />
          <div class="flex flex-col gap-[1.25vh] text-center">
            <h1 data-test="confirm-dialog_title" class="text-h1 font-bold">
              {{ title }}
            </h1>
            <p data-test="confirm-dialog_description" class="text-h3">
              {{ description }}
            </p>
          </div>
        </div>
        <div class="flex gap-[2.22vw]">
          <AdkButton
            v-if="secondaryButton"
            class="w-[34.81vw]"
            size="large"
            @press="doAction('cancel')"
            >{{ secondaryButton }}</AdkButton
          >
          <AdkButton
            v-if="primaryButton"
            class="w-[34.81vw]"
            size="large"
            is-primary
            @press="doAction('confirm')"
            >{{ primaryButton }}</AdkButton
          >
        </div>
      </section>
    </div>
  </BaseDialog>
</template>
