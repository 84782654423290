<script lang="ts" setup>
import { useLocale } from '@composables/useLocale'
import { logInfo, scopedLogger } from '@lib/utils/logUtils'
import { useAppStore } from '@stores/app.store'
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BtnSignout from './actions/BtnSignout.vue'

const log = scopedLogger('@components/FooterUserCard.vue')

const { t } = useLocale()
const router = useRouter()
const route = useRoute()
const name = ref()
const path = ref()
const store = useAppStore()

const continueToIdentification = async () => {
  logInfo(log, 'Continue to Identification')
  // navigate to Prehome
  if (name.value !== 'identification') {
    return await router.push({
      name: 'identification',
      query: { from: path.value, name: name.value }
    })
  }
  // show identification if user clicks identification button in login view
  store.toggleLoginTutorial()
}

watch(
  () => [route.name, route.path],
  ([toName, toPath]) => {
    setTimeout(
      () => {
        name.value = toName
        path.value = toPath
      },
      toName === 'identification' ? 500 : 250
    )
  }
)

onMounted(() => {
  name.value = router.currentRoute.value.name
  path.value = router.currentRoute.value.path
})
</script>

<template>
  <!-- Attract -->
  <div
    v-if="name === 'attract'"
    v-ripple
    class="bg-adk-main rounded-t-xl w-full px-[1.67vh] flex flex-col gap-[0.83vh]"
    @click="continueToIdentification()"
  >
    <div class="text-center flex flex-col gap-[0.42vh]">
      <p class="text-h3 font-bold">{{ t('footer.userCard.unlogged.attract.title') }}</p>
      <p class="text-large">{{ t('footer.userCard.unlogged.attract.description') }}</p>
    </div>
    <img src="/images/user/user-card.svg" alt="user-card" class="h-[7.5vh] w-[19.26vw] m-auto" />
  </div>
  <!-- Identification page -->
  <div
    v-else-if="name === 'identification' && !store.showLoginTutorial"
    v-ripple
    class="bg-adk-main rounded-t-xl min-h-full w-full p-[1.67vh] pb-0 flex flex-col gap-[0.42vh] justify-end"
    @click="continueToIdentification()"
  >
    <div class="text-center flex flex-col gap-[0.42vh]">
      <p class="text-small">{{ t('footer.userCard.unlogged.identification.title') }}</p>
      <p class="text-large font-bold">{{ t('footer.userCard.unlogged.description') }}</p>
    </div>
    <img
      src="/images/icons/chevron.svg"
      alt="chevron"
      class="cursor-pointer h-[1.67vh] mb-[0.42vh] w-auto"
    />
  </div>
  <!-- Only when coupons page -->
  <div
    v-else-if="name === 'aop-coupon'"
    class="bg-adk-main rounded-t-xl min-h-full w-full p-[1.67vh] pb-0 flex flex-col gap-[0.42vh] justify-end"
  >
    <div class="text-center flex flex-col gap-[0.42vh]">
      <p class="text-small">{{ t('footer.userCard.coupon.title') }}</p>
      <p class="text-large font-bold">{{ t('footer.userCard.coupon.description') }}</p>
    </div>
    <img
      src="/images/icons/chevron.svg"
      alt="chevron"
      class="cursor-pointer h-[1.67vh] mb-[0.42vh] w-auto"
    />
  </div>
  <!-- User logged -->
  <div
    v-else-if="store.user"
    v-ripple
    data-test="user-card--logged"
    class="rounded-t-xl border border-adk-secondaryGrey h-[11.25vh] w-full py-[1.25vh] px-[2.96vw] flex flex-col gap-[0.42vh] justify-center text-center"
  >
    <img
      src="/images/icons/avatar.svg"
      alt="avatar"
      class="cursor-pointer h-[2.92vh] mb-[0.42vh] w-full"
    />
    <p class="text-large font-bold">{{ store.user.name }}</p>
    <BtnSignout />
  </div>
  <!-- By default unlogged -->
  <div
    v-else
    v-ripple
    class="bg-adk-main rounded-t-xl min-h-full w-full p-[1.67vh] pb-0 flex flex-col gap-[0.42vh]"
    @click="continueToIdentification()"
  >
    <img src="/images/user/user-card.svg" alt="user-card" class="h-[4.17vh] w-auto" />
    <div class="text-center">
      <p class="text-small">{{ t('footer.userCard.unlogged.title') }}</p>
      <p class="text-large font-bold">{{ t('footer.userCard.unlogged.description') }}</p>
    </div>
    <img
      src="/images/icons/chevron.svg"
      alt="chevron"
      class="cursor-pointer h-[1.67vh] mb-[0.42vh] w-auto"
    />
  </div>
</template>
