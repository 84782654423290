import { LogBody, LogOwnLevel } from '@lib/models/internal/log'
import electronLog, { LogFunctions } from 'electron-log'

export const scopedLogger = (scope: string) => electronLog.scope(scope)

// only message
export const logInfo = (logger: LogFunctions, message: string) => {
  const logBody: LogBody = {
    type: LogOwnLevel.INFO,
    kind: undefined,
    message
  } as LogBody
  logger.info(JSON.stringify(logBody))
}

// logs info but object
export const logInfoDebug = (logger: LogFunctions, log: LogBody) => logger.info(JSON.stringify(log))

// log.error only message
export const logError = (logger: LogFunctions, message: string) => {
  const logBody: LogBody = {
    type: LogOwnLevel.ERROR,
    kind: undefined,
    message
  } as LogBody
  logger.error(JSON.stringify(logBody))
}

// logs error but object
export const logErrorDebug = (logger: LogFunctions, log: LogBody) =>
  logger.error(JSON.stringify(log))

export const logDebug = (logger: LogFunctions, log: Record<string, unknown>) =>
  logger.debug(JSON.stringify(log))
