import { z } from 'zod'
import { productDto } from './product'
import { optionsGroupsMainSchema } from './optionGroups'
import { priceTotals, saleTypeUnion } from './common'

const cartAddInput = z.object({
  sessionId: z.string(),
  coupon: z.string().optional(),
  product: productDto.extend({
    units: z.number()
  })
})

export type CartAddInput = z.infer<typeof cartAddInput>

const cartModifyInput = z.object({
  sessionId: z.string(),
  productCartId: z.string(),
  units: z.number().optional(),
  optionsGroups: z.array(optionsGroupsMainSchema).optional()
})

export type CartModifyInput = z.infer<typeof cartModifyInput>

const cartProduct = productDto.extend({
  productCartId: z.string(),
  units: z.number(),
  // price of all units
  priceComputed: z.number(),
  // price unit with extras
  priceWithExtras: z.number()
})

export type CartProduct = z.infer<typeof cartProduct>

// Dto
export const cartDto = z.object({
  totals: priceTotals,
  sessionId: z.string(),
  saleType: saleTypeUnion,
  productsTotals: z.array(cartProduct),
  coupons: z.array(z.string())
})

export type Cart = z.infer<typeof cartDto>

export const endSessionDto = z.object({
  success: z.boolean(),
  description: z.string()
})
