<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  units: number
}>()

const cartUnits = computed(() => {
  if (props.units >= 100) return '+99'
  return props.units.toString()
})
</script>

<template>
  <div class="relative min-w-[5.19vw]">
    <img src="/images/icons/cart.svg" class="block h-[2.92vh] w-auto" alt="cart" />
    <div
      class="absolute right-0 top-0 grid min-h-[1.67vh] min-w-[2.96vw] place-content-center rounded-full bg-adk-red p-1"
    >
      <span data-test="footer-cart__units" class="text-large font-bold text-white">{{
        cartUnits
      }}</span>
    </div>
  </div>
</template>
