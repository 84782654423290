<script lang="ts" setup>
import { useShutdownApp } from '@composables/useShutDownApp'
import { useAppStore } from '@stores/app.store'
import { computed } from 'vue'

const props = defineProps<{
  title?: string
}>()

const store = useAppStore()
const { handleHeaderClick } = useShutdownApp()

const titleStr = computed(() => {
  if (props.title) return props.title
  return store.viewCategory?.title || undefined
})
</script>

<template>
  <header data-test="header" class="h-[10vh] w-full flex flex-col justify-end">
    <div
      class="mb-[1.25vh] mt-auto ml-[7.41vw] mr-[2.22vw] flex flex-row items-center gap-[calc(8.89vw+3.70vw)]"
    >
      <img
        src="/images/mcd.png"
        alt="mcdonalds"
        class="self-end h-[5.83vh] w-auto"
        @click="handleHeaderClick"
      />
      <h1 v-if="titleStr" class="text-h2 font-bold self-center">{{ titleStr }}</h1>
    </div>
  </header>
</template>
