<script lang="ts" setup>
import { useLocale } from '@composables/useLocale'

const { t } = useLocale()
</script>

<template>
  <slot></slot>
  <section data-test="footer" class="w-full h-[20.83vh] flex flex-row justify-center gap-[6.67vw]">
    <!-- Identification user container -->
    <slot name="identification"></slot>

    <div class="flex flex-col gap-[0.83vh] h-full w-[44.44vw]">
      <!-- Primary button section -->
      <slot name="primary-actions"></slot>

      <!-- Secondary button section -->
      <slot name="secondary-actions"></slot>
      <!-- Kcal disclaimer -->
      <p class="text-xsmall mb-14">
        {{ t('footer.disclaimer') }}
      </p>
    </div>
  </section>
</template>
