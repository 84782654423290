<script lang="ts" setup>
import AdkButton from '@components/ui/AdkButton.vue'
import { useCartActions } from '@composables/useCartActions'
import { useLocale } from '@composables/useLocale'

const { t } = useLocale()
const { cartUnits, navigateSeeOrder } = useCartActions()
</script>

<template>
  <AdkButton
    data-test="btn-seeorder"
    class="w-1/2"
    is-primary
    :disabled="!cartUnits"
    size="medium"
    @press="navigateSeeOrder"
    >{{ t('actions.seeOrder') }}</AdkButton
  >
</template>
