import axios from 'axios'
import { BaseService } from '../base.service'
import logRequests from '@lib/utils/axiosUtils'

const apiBaseURL = import.meta.env.RENDERER_VITE_BACKEND_URL
export class BackendService extends BaseService {
  readonly client

  constructor(serviceName: string) {
    super()

    this.client = axios.create({
      baseURL: `${apiBaseURL}`,
      timeout: 10 * 1000 // 10s
    })

    logRequests(`backend/${serviceName}`, this.client)
  }
}
