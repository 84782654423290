export const Currency = {
  CR_CRC: 'CRC',
  US_USD: 'USD'
} as const

export const CurrencyLocaleOverride = {
  [Currency.CR_CRC]: 'es-CR',
  [Currency.US_USD]: 'en-US'
} as const

export const CurrencyFormatOverride = {
  [Currency.CR_CRC]: {
    group: '.',
    decimal: ','
  },
  [Currency.US_USD]: {
    group: ',',
    decimal: '.'
  }
} as const
