<script setup lang="ts">
interface RouteMetaCustom {
  mode?: 'out-in' | 'in-out' | 'default'
  disabled?: boolean
  transition?: string
  duration?: number
  layout?: 'BlankLayout' | 'HomeLayout'
}
import onscan from 'onscan.js/onscan.min.js'
import electronLog from 'electron-log'
import { ComponentInstance, onBeforeMount, shallowRef } from 'vue'
import { RouterView, useRouter } from 'vue-router'
import layouts from './layouts'
import ScannerKeepAlive from '@components/ScannerKeepAlive.vue'
import { disableGlobalUserInteraction, enableGlobalUserInteraction } from '@lib/utils/utils'
import { logError, logInfo, scopedLogger } from '@lib/utils/logUtils'

const log = scopedLogger('App.vue')
const logRouter = scopedLogger('@Router')

const router = useRouter()
const layout = shallowRef<ComponentInstance<unknown> | 'div'>('div')

router.beforeEach(() => {
  disableGlobalUserInteraction()
})

router.beforeResolve(() => {
  setTimeout(() => {
    enableGlobalUserInteraction()
  }, 250)
})

router.afterEach((to) => {
  const layoutName: string = to.meta?.['layout'] as string
  layout.value = layouts[layoutName] || 'div'
  logInfo(logRouter, 'Navigation To ' + to.fullPath)
})

// unhandled errors renderer
electronLog.errorHandler.startCatching({
  showDialog: false,
  onError({ error }) {
    logError(log, 'UNHANDLED_ERROR RENDERER ' + error.message)
  }
})

const getRouteMetaName = (meta: RouteMetaCustom) => {
  if (meta.disabled) return undefined
  return meta?.transition || 'fade'
}

const getRouteMetaMode = (meta: RouteMetaCustom) => {
  return meta?.mode || 'out-in'
}

const getRouteMetaDuration = (meta: RouteMetaCustom) => {
  return meta?.duration || 250
}

onBeforeMount(() => {
  /* init onScan */
  onscan.attachTo(document, {
    reactToPaste: true
  })
})
</script>

<template>
  <ScannerKeepAlive />
  <transition name="fade" mode="out-in">
    <component :is="layout || 'div'">
      <RouterView v-slot="{ Component: component, route }" :key="$route.path">
        <transition
          :name="getRouteMetaName(route.meta)"
          :mode="getRouteMetaMode(route.meta)"
          :duration="getRouteMetaDuration(route.meta)"
        >
          <component :is="component" />
        </transition>
      </RouterView>
    </component>
  </transition>

  <DialogsWrapper />
</template>
