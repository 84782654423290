import { aopCheckCouponDto } from '@lib/models/aop'
import { BackendService } from '../backend.service'
import { scopedLogger } from '@lib/utils/logUtils'
import { LogFunctions } from 'electron-log'

class AopService extends BackendService {
  protected logger: LogFunctions

  constructor() {
    super('aop')

    this.logger = scopedLogger('@services/backend/aop')
  }

  async aopCheckCoupon(coupon: string, sessionId: string) {
    return await this.request(
      {
        method: 'POST',
        url: `/aop/coupon/${coupon}`,
        data: {
          sessionId
        }
      },
      aopCheckCouponDto,
      this.logger
    )
  }
}

export const aopService = new AopService()
